import { useState, useCallback, useMemo } from 'react';
import AddItem from '../../AddItem';
import { Typography, Grid, Card, Box, CardContent, Fade } from '@mui/material';
import PropTypes from 'prop-types';
import CreateQuoteFooter from '../CreateQuoteFooter';
import classnames from '../../../../constants/classNames';
import { SubmittedItemsSummaryHeader } from '../../../Account/SubmitedQuotes/Items/SubmittedItemsSummary';
import SubmittedItemSummary from '../../../Account/SubmitedQuotes/Items/SubmittedItemSummary';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import AddItemsHeader from './AddItemsHeader';
import toast from 'react-hot-toast';

const AddItems = ({ onCreate }) => {
  const [lineItems, setLineItems] = useState([]);
  const [editItem, setEditItem] = useState();
  const [addItemKey, setAddItemKey] = useState(Date.now());
  const [collapsedMobileItems, setCollapsedMobileItems] = useState([]);
  const isMobile = useIsMobileView();
  const toggleCollapsedItem = useCallback((index) => {
    setCollapsedMobileItems(collapsedMobileItems.map((value, i) => (index === i ? !value : value)));
  }, [collapsedMobileItems]);
  const addItemHandler = useCallback(
    (newItem) => {
      const newLineItems = [...lineItems, newItem];
      setLineItems(newLineItems);
      setAddItemKey(Date.now());
      setEditItem();
      setCollapsedMobileItems(newLineItems.map((_, index) => index + 1 !== newLineItems.length));
    },
    [lineItems]
  );
  const removeItemHandler = (itemKey) => setLineItems(lineItems.filter((lineItem) => lineItem.key !== itemKey));
  const editItemHandler = (itemKey) => {
    setEditItem(lineItems.find((item) => item.key === itemKey));
    removeItemHandler(itemKey);
  };

  const formattedItems = useMemo(
    () => lineItems.map((lineItem) => ({
      ...lineItem,
      product_id: lineItem.product?.id?.replace?.('_compatible', '') ?? null,
      original: !lineItem.brand.includes('Compatible'),
    })),
    [lineItems]
  );

  const handleSubmit = () => {
    if (lineItems.some((item) => !item.quantity)) {
      toast.error('Quantity is required for all items.');
      return;
    }
    onCreate(lineItems);
  };

  return (
    <Grid
      id="selling-form"
      container
      direction="column"
      spacing={2}
      className={classnames.quote.create.steps.addItems.container}
    >
      <Grid
        item
        pb={6}
        className={classnames.quote.create.steps.addItems.itemContainer}
      >
        <Card
          sx={{ backgroundColor: 'background.default' }}
          className={classnames.quote.create.steps.addItems.card}
        >
          <AddItemsHeader />
          <CardContent
            className={classnames.quote.create.steps.addItems.content}
            sx={{
              '&:last-child': {
                pb: 0,
              },
              p: 0,
            }}
          >
            <Box p={2}>
              <form>
                <fieldset
                  style={{ border: '1px solid #757575', borderRadius: '8px' }}
                >
                  <legend>
                    <Typography
                      fontSize={{
                        xs: '1.5em !important',
                        md: '2em !important'
                      }}
                      lineHeight="1.1em"
                      fontWeight="bold"
                      className={classnames.quote.create.steps.addItems.title}
                    >
                      Add Items You Want to Sell:
                    </Typography>
                  </legend>
                  <AddItem
                    key={addItemKey}
                    onAddItem={addItemHandler}
                    editItem={editItem}
                  />
                </fieldset>
              </form>
              {formattedItems.length > 0 && !isMobile && (
                <Box
                  sx={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: '2px solid #CCCCCC',
                    mt: 3,
                    py: 1,
                    px: 2,
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                  }}
                >
                  <SubmittedItemsSummaryHeader hasActions />
                </Box>
              )}
              {formattedItems.length > 0 && isMobile && (
                <Box
                  mt={6}
                  mb={1}
                >
                  <Typography
                    fontSize="1.2em"
                    fontWeight="bold"
                  >
                    Items You Are Selling
                  </Typography>
                </Box>
              )}
              {formattedItems.map((lineItem, index) => (
                <Fade
                  key={lineItem.key}
                  in
                  timeout={1500}
                >
                  <Box>
                    <SubmittedItemSummary
                      item={lineItem}
                      index={index}
                      isCollapsed={collapsedMobileItems[index]}
                      toggleCollapse={() => toggleCollapsedItem(index)}
                      editable
                      onRemove={() => removeItemHandler(lineItem.key)}
                      onEdit={() => editItemHandler(lineItem.key)}
                      styles={{ backgroundColor: 'background.default' }}
                      startOpen
                      onQuantityChange={(newQuantity) => setLineItems(
                        lineItems.map((item) => (item.key === lineItem.key
                          ? { ...item, quantity: newQuantity }
                          : item))
                      )}
                    />
                  </Box>
                </Fade>
              ))}
            </Box>
            <CreateQuoteFooter
              onAction={handleSubmit}
              disabled={lineItems.length === 0}
              itemCount={lineItems.length}
              itemTotal={lineItems.reduce(
                (total, current) => total + current.quantity,
                0
              )}
              id="add-quote-items-btn"
              backgroundSx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: '#f0f0f0'
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddItems;
AddItems.propTypes = {
  onCreate: PropTypes.func.isRequired,
};
