import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import URI_CONSTANTS from '../../constants/uri';
import env from '../../constants/env';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({ baseUrl: env.backUrl + URI_CONSTANTS.PRODUCT.GET }),
  endpoints: (builder) => ({
    getProductPage: builder.query({
      query: ({ page = 0, size = 20, brand, query }) => {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('size', size);
        if (brand && brand !== '') params.append('brand', brand);
        if (query && query !== '')params.append('query', query);
        return `?${params.toString()}`;
      },
    }),
    getProductBySku: builder.query({
      query: (sku) => `/${sku}`
    })
  })
});

export const { useGetProductPageQuery, useGetProductBySkuQuery } = productApi;
