import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Link,
  CardHeader,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import SubmittedItemSummary from './SubmittedItemSummary';
import useIsMobileView from '../../../../hooks/useIsMobileView';

const SubmittedItemsSummary = (props) => {
  const { quote, mobileCardProps = {} } = props;
  const isMobile = useIsMobileView();

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          variant="h3"
          component="h2"
          fontWeight="bold"
          pb={2}
        >
          Your quote
          {' '}
          <Typography
            component="span"
            variant="h3"
            sx={{
              color: 'success.main',
            }}
          >
            <Link
              component={RouterLink}
              sx={{ textDecoration: 'none' }}
              to={`/quote/${quote.id}`}
            >
              {`${quote.id}`}
            </Link>
          </Typography>
          {' '}
          contains the following items:
        </Typography>
      </Box>
      {isMobile ? quote.items.map((item, index) => (
        <SubmittedItemSummary
          item={item}
          index={index}
          key={item.id}
          {...mobileCardProps}
        />
      )) : (
        <Card>
          <CardHeader
            sx={{ backgroundColor: '#EEEEEE', borderBottom: '2px solid #CCCCCC' }}
            title={(
              <SubmittedItemsSummaryHeader />
        )}
          />
          <CardContent sx={{ backgroundColor: 'background.default',
            p: 0,
            pb: '8px !important'
          }}
          >
            {quote.items.map((item, index) => (
              <SubmittedItemSummary
                item={item}
                index={index}
                key={item.id}
              />
            ))}
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export const SubmittedItemsSummaryHeader = ({ hasActions }) => (
  <Grid
    container
    sx={{ fontWeight: 500, fontSize: '14px', color: 'black' }}
  >
    <Grid
      item
      xs={1}
    >
      #
    </Grid>
    <Grid
      item
      xs={hasActions ? 3 : 5}
      md={hasActions ? 4 : 5}
    >
      ITEM
    </Grid>
    <Grid
      item
      xs={2}
      textAlign="center"
    >
      QTY
    </Grid>
    <Grid
      item
      xs={2}
      textAlign="center"
    >
      DATE
    </Grid>
    <Grid
      item
      xs={2}
      textAlign="center"
    >
      CONDITION
    </Grid>
  </Grid>
);

export default SubmittedItemsSummary;
SubmittedItemsSummary.propTypes = {
  quote: PropTypes.object.isRequired,
  mobileCardProps: PropTypes.object
};
SubmittedItemsSummaryHeader.propTypes = {
  hasActions: PropTypes.bool
};
