import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LOCALIZATION_DATE_FORMATS } from './constants/dateFormat';
import { store } from './store';
import { Provider } from 'react-redux';
import 'react-slideshow-image/dist/styles.css';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        dateFormats={LOCALIZATION_DATE_FORMATS}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </LocalizationProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
