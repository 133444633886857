import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  CircularProgress,
  TextField,
  FormControl,
  Typography,
  Grid,
  Box,
  FormHelperText,
  ListSubheader,
  MenuItem,
  styled,
  Popper,
  autocompleteClasses,
} from '@mui/material';
import Select from '../Styled/Select';
import Autocomplete from '../Styled/AutoComplete';
import classnames from '../../../constants/classNames';
import ProductSelectOption from './ProductSelectOption';
import { useGetProductPageQuery } from '../../../store/apiSlice/productSlice';
import { useGetBrandsQuery } from '../../../store/apiSlice/wpSlice';
import { debounce } from 'lodash';
import { flattenBrands, formatSku } from '../../../utils/quoteUtils';

const mapOptions = (products) => products.map((product) => ({
  label: `${formatSku(product.sku)} | ${product.label}`,
  value: product.id,
  product,
}));

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    paddingTop: 0,
  },
});

const ProductSelect = ({
  selectedProduct,
  onProductSelect,
  selectedBrand,
  setSelectedBrand,
  placeholder = 'Search product',
  inputWrapper,
  errors,
  ...props
}) => {
  const scrollHeightRef = useRef();
  const [page, setPage] = useState(0);
  const { data: brands, isLoading: loadingBrands } = useGetBrandsQuery();
  const [query, setQuery] = useState('');
  const { data: productsPage, isLoading: loadingProducts } = useGetProductPageQuery({
    page,
    size: 20,
    brand: selectedBrand?.value,
    query,
  });
  const [products, dispatchProducts] = useReducer((state, action) => {
    switch (action.type) {
      case 'add':
        return [...state, ...action.payload];
      case 'new':
        return action.payload;
      default:
        console.warn(`Unexpected action type ${action.type}`);
        return state;
    }
  }, []);
  const [preferredBrand, setPreferredBrand] = useState(null);

  const debouncedQueryChange = useMemo(
    () => debounce((newQuery) => {
      setPage(0);
      setQuery(newQuery);
    }, 500),
    []
  );

  useEffect(() => {
    if (loadingProducts || loadingProducts === undefined) return;

    const newProducts = mapOptions(productsPage.data.content);
    if (productsPage.data.first) scrollHeightRef.current = null;
    const actionType = productsPage.data.first ? 'new' : 'add';
    dispatchProducts({ type: actionType, payload: newProducts });
  }, [productsPage, loadingProducts]);

  useEffect(() => {
    if (scrollHeightRef.current) {
      scrollHeightRef.current();
      scrollHeightRef.current = null;
    }
  }, [products]);

  const onProductSelectedChange = (_, newValue) => {
    if (newValue) {
      const productBrand = newValue.product.attributes.brand;
      setPreferredBrand(productBrand.includes('HP') ? 'HP' : productBrand);
      onProductSelect(newValue);
    }
  };

  const userInputChangeHandler = (_, newValue) => {
    if (newValue === selectedProduct?.label) return;
    debouncedQueryChange(newValue);
    onProductSelect(newValue);
  };

  const handleProductScroll = useCallback(
    (event) => {
      const listboxNode = event.currentTarget;

      const position = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - position <= 1) {
        setPage(page + 1);
        const top = listboxNode.scrollTop;
        scrollHeightRef.current = () => listboxNode.scrollTo({ top });
      }
    },
    [page]
  );

  const onBrandSelectedChange = (event) => {
    const newBrand = event.target.value;
    if (newBrand === 'Other') {
      return;
    }
    setSelectedBrand(newBrand);
    setPage(0);
    if (selectedProduct !== undefined && typeof selectedProduct !== 'string') {
      onProductSelect({ ...selectedProduct, brand: newBrand.value });
    }
  };

  const flattenedGroupBrands = useMemo(() => {
    if (!brands) return null;
    return flattenBrands(brands);
  }, [brands]);

  const brandOptions = useMemo(() => {
    if (!preferredBrand) return flattenedGroupBrands;
    return [
      ...flattenedGroupBrands.filter((b) => (b?.value ?? b).includes(preferredBrand)),
      'Other',
    ];
  }, [flattenedGroupBrands, preferredBrand]);

  const options = useMemo(() => {
    if (!selectedProduct) return [];
    if (products?.length === 0) {
      return [selectedProduct, ...products];
    }
    return products;
  }, [products, selectedProduct]);

  const InputWrapper = inputWrapper || Box;
  return (
    <>
      <Grid
        item
        md={8}
        xs={12}
      >
        <InputWrapper>
          <Typography variant="formLabel">Search for your item: </Typography>
          <Autocomplete
            loading={loadingProducts}
            options={options}
            disablePortal
            variant="formLabel"
            freeSolo
            renderInput={(params) => (
              <TextField
                placeholder="Search By Cartridge Part Number"
                {...params}
              />
            )}
            getOptionLabel={(option) => option.userInput ?? option.label ?? option}
            renderOption={(liProps, option, state) => (
              <ProductSelectOption
                key={option.value ?? option}
                liProps={liProps}
                option={option}
                state={state}
              />
            )}
            placeholder={placeholder}
            value={selectedProduct ?? null}
            onChange={onProductSelectedChange}
            onInputChange={userInputChangeHandler}
            filterOptions={(x) => x}
            className={classnames.form.productSelect}
            ListboxProps={{
              onScroll: handleProductScroll,
            }}
            PopperComponent={StyledPopper}
            {...props}
          />
        </InputWrapper>
        {errors?.product && (
          <FormHelperText error>{errors?.product}</FormHelperText>
        )}
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
      >
        <InputWrapper>
          <Typography
            htmlFor="brandInput"
            variant="formLabel"
          >
            Brand
          </Typography>
          {!loadingBrands ? (
            <FormControl fullWidth>
              <Select
                id="brandInput"
                value={selectedBrand}
                onChange={onBrandSelectedChange}
              >
                <MenuItem
                  value=""
                  sx={{ display: 'none' }}
                />
                {brandOptions.map((brand) => (typeof brand === 'string' ? (
                  <NonBrandItem
                    key={brand}
                    brandName={brand}
                    setPreferredBrand={setPreferredBrand}
                  />
                ) : (
                  <MenuItem
                    value={brand}
                    key={`option_${brand.value}`}
                    sx={{ ml: 1 }}
                  >
                    {brand.label}
                  </MenuItem>
                )))}
              </Select>
            </FormControl>
          ) : (
            <CircularProgress />
          )}
        </InputWrapper>
        {errors?.brand && (
          <FormHelperText error>{errors?.brand}</FormHelperText>
        )}
      </Grid>
    </>
  );
};

const NonBrandItem = ({ brandName, setPreferredBrand }) => {
  if (brandName === 'Other') {
    return (
      <>
        <ListSubheader sx={{ fontWeight: 'bold' }}>Other</ListSubheader>
        <MenuItem
          onClick={() => setPreferredBrand(null)}
          key={brandName}
          sx={{ ml: 1 }}
        >
          Other Brands
        </MenuItem>
      </>
    );
  }

  return <ListSubheader sx={{ fontWeight: 'bold' }}>{brandName}</ListSubheader>;
};

export default ProductSelect;
ProductSelect.propTypes = {
  selectedProduct: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onProductSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inputWrapper: PropTypes.any,
  errors: PropTypes.shape({
    brand: PropTypes.string,
    product: PropTypes.string,
  }),
  selectedBrand: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setSelectedBrand: PropTypes.func.isRequired,
};
NonBrandItem.propTypes = {
  brandName: PropTypes.string.isRequired,
  setPreferredBrand: PropTypes.func.isRequired,
};
