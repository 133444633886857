import PropTypes from 'prop-types';
import { Typography as MuiTypography } from '@mui/material';

const TEXT_LABELS = {
  A: 'Perfect',
  B: 'Imperfect',
  C: 'Damaged',
  D: 'We don\'t pay',
};

const ConditionDisplay = ({ condition, Typography, ...props }) => (Typography ? (
  <Typography {...props}>{TEXT_LABELS[condition]}</Typography>
) : (
  <MuiTypography {...props}>{TEXT_LABELS[condition]}</MuiTypography>
));

export default ConditionDisplay;
ConditionDisplay.propTypes = {
  condition: PropTypes.string.isRequired,
  Typography: PropTypes.elementType,
};
