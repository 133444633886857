import PropTypes from 'prop-types';
import InfiniteToast from '../../InfiniteToast';

const ConditionToast = ({ id }) => (
  <InfiniteToast
    severity="success"
    color="warning"
    id={id}
  >
    We don&apos;t pay for items in D condition
  </InfiniteToast>
);

export default ConditionToast;
ConditionToast.propTypes = {
  id: PropTypes.string.isRequired
};
