import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import env from '../../constants/env';

const BASE_URLS = {
  DEV: '',
  PROD: 'https://cash4toners.com',
  STG: 'https://c4t.stg.tbzapps.com',
};

const baseUrl = BASE_URLS[env.env];

export const wpApi = createApi({
  reducerPath: 'wpApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: () => env.brandsSrc,
    }),
  }),
});

export const { useGetBrandsQuery } = wpApi;
