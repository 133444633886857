import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography } from '@mui/material';
import PrimaryActionButton from '../../Buttons/PrimaryActionButton';
import classnames from '../../../constants/classNames';

const CreateQuoteFooter = ({ onAction, disabled, itemCount, itemTotal, backgroundSx = {}, ...buttonProps }) => {
  const [isActionLoading, setIsActionLoading] = useState(false);
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onClickHandler = async () => {
    setIsActionLoading(true);
    await onAction();
    if (mounted.current) setIsActionLoading(false);
  };

  return (
    <Card sx={{
      backgroundColor: 'background.default', ...backgroundSx }}
    >
      <CardContent sx={{ pb: '16px !important' }}>
        <Box
          display="flex"
          justifyContent={itemCount ? 'space-between' : 'flex-end'}
          alignItems="center"
          sx={{
            backgroundColor: 'background.default',
            width: '100%',
            zIndex: 2,
            ...backgroundSx
          }}
          className={classnames.quote.create.footer.container}
        >
          {!!itemCount && (
          <Box pl={3}>
            <Typography
              color="#59BB67"
              fontWeight="bold"
            >
              {itemTotal}
              {' '}
              { itemTotal > 1 ? 'products' : 'product'}
            </Typography>
          </Box>
          )}
          {onAction && (
          <PrimaryActionButton
            onClick={onClickHandler}
            loading={isActionLoading}
            disabled={disabled}
            {...buttonProps}
          >
            {isActionLoading ? 'Getting paid...' : 'Get Paid'}
          </PrimaryActionButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateQuoteFooter;
CreateQuoteFooter.propTypes = {
  onAction: PropTypes.func,
  disabled: PropTypes.bool,
  backgroundSx: PropTypes.object,
  itemCount: PropTypes.number,
  itemTotal: PropTypes.number
};
