const generateNumberArray = (
  length,
  calculateNumberFunc = (index) => index
) => {
  const numberArray = [];
  for (let i = 0; i < length; i++) numberArray.push(calculateNumberFunc(i));
  return numberArray;
};

const generateId = () => Math.floor(Date.now() * Math.random() * 1000);

const byteToKb = (bytes) => Math.round(bytes / 1000);

export { generateNumberArray, generateId, byteToKb };
