const classNames = {
  general: {
    pageWrapper: 'c4tv-page-wrapper',
    loadingScreen: 'c4tv-loading-screen',
    scrollBar: 'c4tv-scrollbar',
    imageWrapper: 'c4tv-image-wrapper',
    imageContainer: 'c4tv-image-container',
    imageContainerText: 'c4tv-image-container-text',
    imageContainerRemoveButton: 'c4tv-image-container-remove-button',
    backdrop: 'c4tv-backdrop',
    accordion: 'c4tv-accordion',
  },
  account: {
    mainPage: 'c4tv-account-main-page',
    mainPageFont: 'c4tv-account-main-page-font',
    mainPageTitle: 'c4tv-account-main-page-title',
    header: {
      box: 'c4tv-account-header',
      card: 'c4tv-account-header-card',
      cardContent: 'c4tv-account-header-card-content'
    },
    address: {
      box: 'c4tv-account-address',
      textAddress: 'c4tv-account-address-text',
      textCity: 'c4tv-account-address-city-text',
      textPhone: 'c4tv-account-address-phone-text',
      textName: 'c4tv-account-address-name-text',
      textCompany: 'c4tv-account-address-company-text',
    },
    tabs: {
      desktopTabs: 'c4tv-account-tabs',
      desktopTab: 'c4tv-account-tab',
      mobileTabs: 'c4tv-account-mobileTabs',
      mobileTab: 'c4tv-account-mobileTab',
      mobileTabToggler: 'c4tv-account-mobileTab-toggler',
      tabContent: 'c4tv-account-tab-content',
    },
    notifications: {
      mainPage: 'c4tv-account-notifications-messages',
      title: 'c4tv-account-notifications-messages-title',
      filterText: 'c4tv-account-notifications-messages-filter-text',
      filterInput: 'c4tv-account-notifications-messages-filter-input',
      container: 'c4tv-account-notifications-messages-container',
      box: 'c4tv-account-notifications-messages-list-container',
      emptyBox: 'c4tv-account-notifications-messages-empty-container',
      notification: 'c4tv-account-notifications-messages-notification',
      notificationIcon: 'c4tv-account-notifications-messages-notification-icon',
      notificationTitle: 'c4tv-account-notifications-messages-notification-title',
      notificationDate: 'c4tv-account-notifications-messages-notification-date',
      notificationContent: 'c4tv-account-notifications-messages-notification-content',
    },
    personalInformation: {
      container: 'c4tv-account-info-container',
      title: 'c4tv-account-info-title',
      card: 'c4tv-account-info-card',
      content: 'c4tv-account-info-content',
      form: 'c4tv-account-info-form',
      userDetails: {
        info: 'c4tv-account-info-user-details',
        card: 'c4tv-account-info-user-details-card',
        title: 'c4tv-account-info-user-details-title',
        name: 'c4tv-account-info-user-details-name',
        company: 'c4tv-account-info-user-details-company',
        address: 'c4tv-account-info-user-details-address',
        city: 'c4tv-account-info-user-details-city',
        email: 'c4tv-account-info-user-details-email',
      }
    },
    submittedQuotes: {
      container: 'c4tv-account-submittedquotes-container',
      title: 'c4tv-account-submittedquotes-title',
      scroll: 'c4tv-account-submittedquotes-scroll',
    }
  },
  auth: {
    login: {
      form: 'c4tv-auth-login-form',
    },
    signin: {
      form: 'c4tv-auth-signin-form',
    }
  },
  buttons: {
    accept: 'c4tv-buttons-accept',
    decline: 'c4tv-buttons-decline',
    arrow: 'c4tv-buttons-arrow',
    delete: 'c4tv-buttons-delete',
    edit: 'c4tv-buttons-edit',
    primary: 'c4tv-buttons-primary',
  },
  filledTabs: {
    tabs: 'c4tv-filled-tabs',
    tab: 'c4tv-filled-tab',
  },
  form: {
    conditionPicker: {
      starButton: 'c4tv-formik-condition-star',
    },
    input: {
      container: 'c4tv-formik-input-container',
      label: 'c4tv-formik-input-label',
      error: 'c4tv-formik-input-error',
    },
    autocomplete: 'c4tv-formik-autocomplete',
    select: 'c4tv-formik-select',
    productSelect: 'c4tv-formik-product-select',
    addImage: {
      container: 'c4tv-formik-addimage-container',
      button: 'c4tv-formik-addimage-button'
    },
    addFile: {
      container: 'c4tv-formik-addfile-container',
      button: 'c4tv-formik-addfile-button'
    }
  },
  quote: {
    container: {
      mainContainer: 'c4tv-quote-main-container'
    },
    create: {
      footer: {
        container: 'c4tv-quote-create-footer-container',
        text: 'c4tv-quote-create-footer-text'
      },
      steps: {
        addItems: {
          container: 'c4tv-quote-create-steps-additems-container',
          itemContainer: 'c4tv-quote-create-steps-additems-item-container',
          card: 'c4tv-quote-create-steps-additems-card',
          content: 'c4tv-quote-create-steps-additems-content',
          title: 'c4tv-quote-create-steps-additems-title',
        },
        userDetails: {
          container: 'c4tv-quote-create-steps-userdetails-container',
          text: 'c4tv-quote-create-steps-userdetails-text',
        },
        updateUserDetails: {
          container: 'c4tv-quote-create-steps-updateuserdetails-container',
          text: 'c4tv-quote-create-steps-updateuserdetails-text',
        },
      }
    },
    display: {
      head: {
        container: 'c4tv-display-head-container',
        title: 'c4tv-display-head-title',
        date: 'c4tv-display-head-date',
        content: 'c4tv-display-head-content',
      },
      quote: {
        container: 'c4tv-display-quote-container',
      }
    },
    lineItem: {
      container: 'c4tv-lineitem-container',
      label: 'c4tv-lineitem-label',
      quantity: 'c4tv-lineitem-quantity',
      condition: 'c4tv-lineitem-condition',
      buttons: 'c4tv-lineitem-buttons',
    },
    thanks: {
      container: 'c4tv-thanks-container',
      title: 'c4tv-thanks-title',
      text: 'c4tv-thanks-text',
      footer: 'c4tv-thanks-footer',
    }
  }

};

export default classNames;
