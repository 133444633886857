import spin from './spin.module.css';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import classnames from '../../../constants/classNames';

const PrimaryActionButton = ({ onClick, children, loading, disabled, ...props }) => {
  const imgClass = loading ? spin.rotate : '';

  return (
    <Button
      startIcon={(
        <img
          src="/vendor/images/colorful-circle.svg"
          alt="c4t logo"
          className={imgClass}
          style={{ height: '1.5rem' }}
        />
    )}
      color="secondary"
      onClick={onClick}
      variant="contained"
      disabled={loading || disabled}
      className={classnames.buttons.primary}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryActionButton;
PrimaryActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
