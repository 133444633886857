import PropTypes from 'prop-types';
import { Select as MuiSelect } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from '../../../constants/classNames';

const Select = ({ children, ...props }) => (
  <MuiSelect
    IconComponent={KeyboardArrowDownIcon}
    className={classnames.form.select}
    {...props}
  >
    {children}
  </MuiSelect>
);

export default Select;
Select.propTypes = {
  children: PropTypes.node.isRequired,
};
