import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { productApi } from './apiSlice/productSlice';
import { wpApi } from './apiSlice/wpSlice';

export const store = configureStore({
  reducer: {
    [productApi.reducerPath]: productApi.reducer,
    [wpApi.reducerPath]: wpApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(productApi.middleware, wpApi.middleware),
});

setupListeners(store.dispatch);
