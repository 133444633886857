import AddItems from '../Quote/CreateQuote/Steps/AddItems';

const CreateQuoteForm = () => {
  const onCreateQuoteHandler = (newQuote) => {
    const quoteStr = JSON.stringify(newQuote);
    localStorage.setItem('newQuote', quoteStr);
    window.location.href = '/vendor/sell';
  };
  return <AddItems onCreate={onCreateQuoteHandler} />;
};

export default CreateQuoteForm;
