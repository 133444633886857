import PropTypes from 'prop-types';
import { useGetBrandsQuery } from '../../store/apiSlice/wpSlice';
import { useCallback, useEffect, useState } from 'react';
import ProductSelect from '../Form/ProductSelect';
import {
  FormControl,
  Grid,
  Button,
  Typography,
  TextField,
  FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import ConditionPicker from '../Form/ConditionPicker';
import { Box } from '@mui/system';
import useIsMobileView from '../../hooks/useIsMobileView';
import { flattenBrands } from '../../utils/quoteUtils';
import QuantitySelector from '../Form/QuantitySelector';
import ConditionToast from './CreateQuote/ConditionToast';
import { toast } from 'react-hot-toast';

const validate = (product, brand, quantity, condition, expirationDate) => {
  const errors = {};
  if (!product || product.userInput === '') {
    errors.product = 'Product is required';
  } else if (
    product.product?.requires_expiration_date
    && expirationDate === null
  ) {
    errors.requires_expiration_date = 'Expiration date is required for inkjet cartridges';
  }

  if (!brand || brand === '') {
    errors.brand = 'Brand is required';
  }

  if (!quantity || !quantity > 0) {
    errors.quantity = 'Item quantity is required';
  }
  if (!condition) {
    errors.condition = 'Item condition is required';
  }

  return errors;
};

const SquareWrapper = ({ children, ...props }) => (
  <Box
    display="flex"
    flexDirection="column"
    {...props}
  >
    {children}
  </Box>
);

const AddItem = ({ onAddItem, editItem, styles }) => {
  const isMobile = useIsMobileView();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [expirationDate, setExpirationDate] = useState(null);
  const [condition, setCondition] = useState();
  const [errors, setErrors] = useState(null);
  const [conditionModalShown, setConditionModalShown] = useState(false);
  const [productSelectKey, setProductSelectKey] = useState(
    new Date().getTime()
  );

  const { data: brands, isLoading: loadingBrands } = useGetBrandsQuery();

  useEffect(() => {
    if (loadingBrands) return;
    if (!editItem) {
      setSelectedProduct();
      setQuantity(1);
      setCondition();
      setExpirationDate(null);
    } else {
      setSelectedProduct(
        editItem.product
          ? { product: editItem.product, label: editItem.product.label }
          : { userInput: editItem.userInput }
      );
      const brand = flattenBrands(brands).find(
        (brandItem) => brandItem.value === editItem.brand
      );
      setSelectedBrand(brand);
      setQuantity(editItem.quantity);
      setCondition(editItem.condition);
      setExpirationDate(editItem?.expirationDate ?? editItem?.boxDate ?? null);
      setProductSelectKey(new Date().getTime());
    }
  }, [editItem, loadingBrands, brands]);

  const onAddItemHandler = () => {
    const validationErrors = validate(
      selectedProduct,
      selectedBrand,
      quantity,
      condition,
      expirationDate,
    );
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      const expDate = selectedProduct.product?.requires_expiration_date
        ? expirationDate
        : null;
      onAddItem({
        product: selectedProduct.product,
        userInput:
          typeof selectedProduct === 'string'
            ? selectedProduct
            : selectedProduct.userInput,
        brand: selectedBrand.value,
        quantity: +quantity,
        condition,
        key: Date.now(),
        expirationDate: expDate,
        boxDate: selectedProduct.product?.requires_expiration_date ? null : expirationDate,
      });
    }
  };

  const onProductSelectHandler = useCallback((product) => {
    setSelectedProduct(product);
  }, []);
  const handleConditionChange = useCallback((newCondition) => {
    setCondition(newCondition);
    if (!conditionModalShown && newCondition === 'D') {
      setConditionModalShown(true);
      toast.custom(<ConditionToast id="upload-email-toast" />, { duration: Infinity, id: 'upload-email-toast' });
    }
  }, [conditionModalShown]);

  const handleDatePickerViewChange = useCallback((view) => {
    if (view !== 'year') return;
    const selectedYear = `${(expirationDate ?? new Date()).getFullYear()}`;
    setTimeout(() => {
      const yearButton = [...document.querySelectorAll('.PrivatePickersYear-yearButton')].filter((button) => button.innerHTML === selectedYear)[0];
      yearButton.scrollIntoView({ behavior: 'auto' });
    }, 50);
  }, [expirationDate]);

  return (
    <Grid
      container
      direction="column"
      sx={styles}
    >
      <Grid
        container
        item
        spacing={2}
      >
        <ProductSelect
          key={productSelectKey}
          fullWidth
          selectedProduct={selectedProduct}
          onProductSelect={onProductSelectHandler}
          placeholder="Start typing your item number..."
          inputWrapper={SquareWrapper}
          errors={errors}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
        <Grid
          item
          md={3}
          xs={12}
        >
          <SquareWrapper>
            <Typography
              htmlFor="quantityInput"
              variant="formLabel"
            >
              Quantity
            </Typography>
            <FormControl fullWidth>
              <QuantitySelector
                value={quantity}
                onChange={setQuantity}
              />
            </FormControl>
            {errors?.quantity && (
              <FormHelperText error>{errors.quantity}</FormHelperText>
            )}
          </SquareWrapper>
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <SquareWrapper>
            <Typography variant="formLabel">
              Condition
              {isMobile && (
                <span style={{ fontWeight: '300' }}> (Tap and hold for more details)</span>
              )}
            </Typography>
            <ConditionPicker
              condition={condition}
              setCondition={handleConditionChange}
            />
          </SquareWrapper>
          {errors?.condition && (
            <FormHelperText error>{errors.condition}</FormHelperText>
          )}
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <>
            <SquareWrapper>
              <Typography
                htmlFor="expirationDateInput"
                variant="formLabel"
              >
                { selectedProduct?.product?.requires_expiration_date ? 'Expiration date' : 'Date on Box (Optional)'}
              </Typography>
              <DatePicker
                id="expirationDateInput"
                value={expirationDate}
                onChange={setExpirationDate}
                onViewChange={handleDatePickerViewChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </SquareWrapper>
            {selectedProduct?.product?.requires_expiration_date && errors?.requires_expiration_date && (
              <FormHelperText error>
                {errors.requires_expiration_date}
              </FormHelperText>
            )}
          </>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Button
            variant="outlined"
            onClick={onAddItemHandler}
            sx={
              isMobile ? { mt: 2, fontWeight: 'bold' } : { fontWeight: 'bold' }
            }
          >
            + Add Item to list
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddItem;
AddItem.propTypes = {
  onAddItem: PropTypes.func.isRequired,
  editItem: PropTypes.shape({
    product: PropTypes.object,
    quantity: PropTypes.number,
    condition: PropTypes.string,
    expirationDate: PropTypes.object,
    brand: PropTypes.string,
    key: PropTypes.number,
    userInput: PropTypes.string,
    boxDate: PropTypes.object
  }),
  styles: PropTypes.object,
};
SquareWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
