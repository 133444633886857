import PropTypes from 'prop-types';
import { Alert, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';

const InfiniteToast = ({ id, children, severity = 'success', color = 'warning' }) => {
  const handleClose = () => toast.remove(id);
  return (
    <Box position="relative">
      <Alert
        severity={severity}
        color={color}
        sx={{
          '& .MuiAlert-icon': {
            alignItems: 'center',
          },
        }}
      >
        {children}
        <IconButton
          size="small"
          aria-label="Close message"
          sx={{ ml: 2 }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>
    </Box>
  );
};

export default InfiniteToast;
InfiniteToast.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string.isRequired
};
