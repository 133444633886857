import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { formatLabel, formatSku } from '../../../utils/quoteUtils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ProductSelectOption = (props) => {
  const { liProps, option } = props;

  return (
    <li
      {...liProps}
    >
      {typeof option === 'string' ? (
        <Box sx={{ pl: 2, display: 'flex', alignItems: 'center' }}>
          <AddCircleOutlineIcon />
          <Typography
            sx={{ pl: 2 }}
          >
            Add
            {' '}
            {option}
          </Typography>
        </Box>
      ) : (
        <>
          <img
            alt=""
            src={option?.product?.attributes?.image?.thumbnail ?? '/wp-content/themes/cash4toners/img/no-img.jpg'}
            style={{
              height: '4rem',
              mixBlendMode: 'multiply'
            }}
          />
          <Typography sx={{ pl: 2 }}>
            {formatSku(option?.product?.sku)}
            <br />
            {formatLabel(option?.product?.label ?? '')}
          </Typography>
        </>
      )}
    </li>
  );
};

export default ProductSelectOption;
ProductSelectOption.propTypes = {
  liProps: PropTypes.object.isRequired,
  option: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  state: PropTypes.object.isRequired,
};
