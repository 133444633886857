import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@mui/system';
import theme from './theme';
import { CssBaseline } from '@mui/material';
import './scss/index.scss';
import 'react-slideshow-image/dist/styles.css';
import CreateQuoteForm from './components/wordpress/CreateQuoteForm';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <CreateQuoteForm />
    </ThemeProvider>
  );
}

export default App;
