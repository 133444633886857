import { Autocomplete as MuiAutoComplete } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from '../../../constants/classNames';

const AutoComplete = (props) => (
  <MuiAutoComplete
    popupIcon={<KeyboardArrowDownIcon />}
    className={classnames.form.autocomplete}
    {...props}
  />
);

export default AutoComplete;
