import PropTypes from 'prop-types';
import ConditionDisplay from '../../../Quote/ConditionDisplay';
import { parseDay } from '../../../../utils/dateUtils';
import { format } from 'date-fns';
import DATE_FORMATS from '../../../../constants/dateFormat';
import { useGetProductBySkuQuery } from '../../../../store/apiSlice/productSlice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMemo } from 'react';
import { IconButton, Grid } from '@mui/material';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import { ReactComponent as CollaseOpenIcon } from '../../../../assets/svg/collapse_open.svg';
import { ReactComponent as CollaseCloseIcon } from '../../../../assets/svg/collapse_close.svg';
import ItemLabelBrandCell from './Item/ItemLabelBrandCell';
import MobileItem from './Item/MobileItem';
import { buildManualProduct } from '../../../../utils/quoteUtils';
import QuantitySelector from '../../../Form/QuantitySelector';

const SubmittedItemSummary = (props) => {
  const { item, index, onRemove, onEdit, editable, onQuantityChange } = props;
  const { data: apiItemProduct, isLoading } = useGetProductBySkuQuery(
    item.product_id
  );
  const expirationDate = useMemo(() => {
    let date = null;
    if (item.expirationDate) date = item.expirationDate;
    if (item.boxDate) date = item.boxDate;
    if (item.expiration_date) date = item.expiration_date;
    if (item.box_date) date = item.box_date;
    if (date) return format(typeof date === 'string' ? parseDay(date) : date, DATE_FORMATS.SLASHED.NUMERIC);
    return null;
  }, [item.expirationDate, item.boxDate, item.expiration_date, item.box_date]);
  const displayActions = Boolean(onRemove || onEdit);
  const isMobile = useIsMobileView();

  const isManualItem = !item.product_id;

  const itemProduct = useMemo(
    () => (isManualItem
      ? buildManualProduct(item.userInput, item.brand)
      : apiItemProduct),
    [apiItemProduct, isManualItem, item.brand, item.userInput]
  );

  if (isMobile) {
    return <MobileItem {...props} />;
  }

  return (
    <Grid
      container
      sx={{
        fontWeight: 600,
        fontSize: '14px',
        color: '#000000',
        borderTop: index > 0 && 'solid 2px rgba(204, 204, 204, 1)',
        '& .MuiGrid-item': {
          fontSize: '15px',
          fontWeight: 500,
          color: 'black',
        },
      }}
      pt={1}
      px={2}
      mb={1}
    >
      <Grid
        item
        xs={1}
        display="flex"
        alignItems="center"
      >
        {index + 1}
      </Grid>
      <Grid
        item
        md={displayActions ? 4 : 5}
      >
        <ItemLabelBrandCell
          isLoading={isLoading}
          item={item}
          itemProduct={itemProduct}
          original={item.original}
        />
      </Grid>
      <Grid
        item
        xs={displayActions ? 1 : 2}
        md={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {editable ? (
          <QuantitySelector
            value={item.quantity}
            onChange={onQuantityChange}
            sx={{
              mx: 3,
            }}
          />
        ) : (
          item.quantity
        )}
      </Grid>
      <Grid
        item
        xs={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {expirationDate}
      </Grid>
      <Grid
        item
        xs={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ConditionDisplay
          condition={item.condition}
          fontSize="15px"
          fontWeight={500}
          color="black"
        />
      </Grid>
      {displayActions && (
        <Grid
          item
          xs={2}
          md={1}
          display="flex"
          alignItems="center"
          gap={2}
        >
          {onEdit && (
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          )}
          {onRemove && (
            <IconButton onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const CollapsableButton = ({ open, onClick }) => (
  <IconButton onClick={onClick}>
    {open ? <CollaseCloseIcon /> : <CollaseOpenIcon />}
  </IconButton>
);

export default SubmittedItemSummary;

SubmittedItemSummary.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  startOpen: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  editable: PropTypes.bool,
  onQuantityChange: PropTypes.func,
};
CollapsableButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
