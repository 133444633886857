import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Collapse,
  Button,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { parseDay } from '../../../../../utils/dateUtils';
import { format } from 'date-fns';
import DATE_FORMATS from '../../../../../constants/dateFormat';
import { CATEGORY } from '../../../../../constants/quoteConstants';
import { useGetProductBySkuQuery } from '../../../../../store/apiSlice/productSlice';
import ConditionDisplay from '../../../../Quote/ConditionDisplay';
import { ReactComponent as CollaseOpenIcon } from '../../../../../assets/svg/collapse_open.svg';
import { ReactComponent as CollaseCloseIcon } from '../../../../../assets/svg/collapse_close.svg';
import { formatCurrency } from '../../../../../utils/moneyUtils';
import { formatSku } from '../../../../../utils/quoteUtils';

const HeaderTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '1em',
}));
const CollapsableKeyTypography = styled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '1em',
}));
const CollapsableValueTypography = styled(Typography)(() => ({
  fontSize: '1em',
}));

const MobileItem = ({
  item,
  index,
  hidePrice,
  onRemove,
  onEdit,
  alwaysOpen,
  startOpen = false,
  isCollapsed = null,
  toggleCollapse,
  editable,
  onQuantityChange,
  ...props
}) => {
  const { data: itemProduct, isLoading } = useGetProductBySkuQuery(
    item.product_id
  );
  const expirationDate = useMemo(() => {
    const dateData = item.expirationDate
      ?? item.expiration_date
      ?? item.boxDate
      ?? item.box_date;
    if (!dateData) return false;
    const date = typeof dateData === 'string' ? parseDay(dateData) : dateData;
    return date ? format(date, DATE_FORMATS.SLASHED.NUMERIC) : false;
  }, [item.expirationDate, item.expiration_date, item.boxDate, item.box_date]);
  const displayActions = Boolean(onRemove || onEdit);
  const [isMobileCollapsed, setIsMobileCollapsed] = useState(
    !startOpen && !alwaysOpen
  );
  const toggleCollapsed = () => {
    setIsMobileCollapsed(!isMobileCollapsed);
    toggleCollapse?.();
  };
  const collapsed = useMemo(() => {
    if (isCollapsed !== null) return isCollapsed;
    return isMobileCollapsed;
  }, [isCollapsed, isMobileCollapsed]);
  const displayOffer = !hidePrice && Boolean(item.total) && item.category !== CATEGORY.UNWANTED;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const productLabel = useMemo(() => {
    if (isLoading) return '';
    if (itemProduct?.sku) return formatSku(itemProduct.sku);
    if (itemProduct?.label) return itemProduct.label;
    return item?.userInput ?? '';
  }, [isLoading, item?.userInput, itemProduct?.label, itemProduct?.sku]);

  return (
    <Box
      border="1px solid #9C9C9C"
      borderRadius="8px"
      my={2}
      {...props}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom={!collapsed && '1px solid #9C9C9C'}
        p={1}
      >
        <Box
          display="flex"
          gap={3}
          alignItems="center"
        >
          {Number.isInteger(index) && (
            <HeaderTypography>
              Item #
              {index + 1}
            </HeaderTypography>
          )}
          <HeaderTypography>{productLabel}</HeaderTypography>
          {!displayOffer && (
            <HeaderTypography color="highlight.main">
              x
              {item.quantity}
            </HeaderTypography>
          )}
        </Box>
        {displayOffer && (
          <Box
            display="flex"
            gap={2}
          >
            {!isSmall && <HeaderTypography>Total:</HeaderTypography>}
            <HeaderTypography color="highlight.main">
              {formatCurrency(item.total)}
            </HeaderTypography>
          </Box>
        )}
        {!alwaysOpen && (
          <CollapsableButton
            open={!collapsed}
            onClick={toggleCollapsed}
          />
        )}
      </Box>
      <Collapse in={!collapsed}>
        <Box
          display="flex"
          pb={1}
        >
          <Box
            px={1}
            display="flex"
            flexDirection="column"
          >
            <CollapsableKeyTypography>Brand:</CollapsableKeyTypography>
            <CollapsableKeyTypography>QTY:</CollapsableKeyTypography>
            <CollapsableKeyTypography>Condition:</CollapsableKeyTypography>
            {expirationDate && (
              <CollapsableKeyTypography>Date:</CollapsableKeyTypography>
            )}
            {displayOffer && (
              <CollapsableKeyTypography>Offer:</CollapsableKeyTypography>
            )}
          </Box>
          <Box
            px={1}
            display="flex"
            flexDirection="column"
          >
            <CollapsableValueTypography>
              {item?.brand ?? itemProduct?.attributes?.brand ?? 'loading...'}
            </CollapsableValueTypography>
            <CollapsableValueTypography>
              {item.quantity}
            </CollapsableValueTypography>
            <ConditionDisplay
              Typography={CollapsableValueTypography}
              condition={item.condition}
            />
            {expirationDate && (
              <CollapsableValueTypography>
                {expirationDate}
              </CollapsableValueTypography>
            )}
            {displayOffer && (
              <CollapsableValueTypography>
                {formatCurrency(item.price)}
              </CollapsableValueTypography>
            )}
          </Box>
        </Box>
        {displayActions && (
          <Box
            display="flex"
            justifyContent="space-around"
            pb={1}
          >
            {onEdit && (
              <Button
                onClick={onEdit}
                color="highlight"
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                Update
              </Button>
            )}
            {onRemove && (
              <Button
                onClick={onRemove}
                color="error"
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                Remove
              </Button>
            )}
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

const CollapsableButton = ({ open, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{ width: '40px' }}
  >
    {open ? <CollaseCloseIcon /> : <CollaseOpenIcon />}
  </IconButton>
);

export default MobileItem;

MobileItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  startOpen: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  hidePrice: PropTypes.bool,
  editable: PropTypes.bool,
  onQuantityChange: PropTypes.func
};
CollapsableButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
