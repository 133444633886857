import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const AddItemsHeader = () => (
  <Box
    pt={4}
    pb={3}
  >
    <Typography
      variant="h2"
      sx={{
        fontFamily: 'Lexend,sans-serif',
        fontSize: '42px !important',
        fontWeight: 700,
        lineheight: '52px',
        letterspacing: '-1px',
        marginBottom: '24px',
        textAlign: 'center'
      }}
    >
      Selling Toner Made Easy
    </Typography>
    <Box
      sx={{
        justifyContent: 'center',
        display: {
          xs: 'none',
          md: 'flex'
        },
        '.arrow': {
          display: 'flex'
        },
        ul: {
          xs: {
            flexDirection: 'column',
          },
          md: {
            flexDirection: 'row',
            justifyContent: 'center'
          }
        }
      }}
    >
      <ul
        className="selling-flow"
        style={{ display: 'flex', gap: '1em' }}
      >
        <li>
          <img
            src="https://cash4toners.com/wp-content/themes/cash4toners/svg/printer.svg"
            alt="printer"
            width="20"
            height="21"
          />
          <span>Choose Your Item and Brand</span>
        </li>
        <li className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
          >
            <path
              fill="#092F6F"
              d="M12.116.235a.633.633 0 0 0-.945-.043.664.664 0 0 0 .043.965l4.601 4.712H.608A.65.65 0 0 0 0 6.525a.65.65 0 0 0 .608.656h15.207l-4.608 4.705a.679.679 0 0 0 0 .922.63.63 0 0 0 .902 0l5.7-5.83-.001.001a.649.649 0 0 0 0-.922L12.116.235Z"
            />
          </svg>
        </li>
        <li>
          <img
            src="https://cash4toners.com/wp-content/themes/cash4toners/svg/products.svg"
            alt="products"
            width="21"
            height="21"
          />
          <span>Enter Quantity</span>
        </li>
        <li className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
          >
            <path
              fill="#092F6F"
              d="M12.116.235a.633.633 0 0 0-.945-.043.664.664 0 0 0 .043.965l4.601 4.712H.608A.65.65 0 0 0 0 6.525a.65.65 0 0 0 .608.656h15.207l-4.608 4.705a.679.679 0 0 0 0 .922.63.63 0 0 0 .902 0l5.7-5.83-.001.001a.649.649 0 0 0 0-.922L12.116.235Z"
            />
          </svg>
        </li>
        <li>
          <img
            src="https://cash4toners.com/wp-content/themes/cash4toners/svg/open-box.svg"
            alt="open-box"
            width="22"
            height="24"
          />
          <span>Select Condition</span>
        </li>
        <li className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
          >
            <path
              fill="#092F6F"
              d="M12.116.235a.633.633 0 0 0-.945-.043.664.664 0 0 0 .043.965l4.601 4.712H.608A.65.65 0 0 0 0 6.525a.65.65 0 0 0 .608.656h15.207l-4.608 4.705a.679.679 0 0 0 0 .922.63.63 0 0 0 .902 0l5.7-5.83-.001.001a.649.649 0 0 0 0-.922L12.116.235Z"
            />
          </svg>
        </li>
        <li>
          <img
            src="https://cash4toners.com/wp-content/themes/cash4toners/svg/document.svg"
            alt="document"
            width="15"
            height="19"
          />
          <span>Submit Quote</span>
        </li>
      </ul>
    </Box>
  </Box>
);

export default AddItemsHeader;
