const CONDITIONS = [
  'A',
  'B',
  'C',
  'D'
];

const STATUS = {
  PENDING: 'PENDING',
  IN_REVIEW: 'IN_REVIEW',
  OFFER_SENT: 'OFFER_SENT',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  UNWANTED: 'UNWANTED',
  PENDING_SHIPMENT: 'PENDING_SHIPMENT',
  SHIPPED: 'SHIPPED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  DELIVERED: 'DELIVERED',
  PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
  RECEIVED: 'RECEIVED',
  DISPUTE: 'DISPUTE',
  COMPLETED: 'COMPLETED',
  TRASH: 'TRASH',
};

const QUOTE_STEPS = {
  PENDING: {
    key: 'PENDING',
    ajaxKeys: [STATUS.PENDING, STATUS.IN_REVIEW],
  },
  OFFER_SENT: {
    key: 'OFFER_SENT',
    ajaxKeys: [STATUS.OFFER_SENT],
  },
  ACCEPTED: {
    key: 'ACCEPTED',
    ajaxKeys: [STATUS.ACCEPTED],
  },
  PAYMENT: {
    key: 'PAYMENT',
    ajaxKeys: [STATUS.PENDING_PAYMENT],
  },
  SHIPPED: {
    key: 'SHIPPED',
    ajaxKeys: [
      STATUS.PENDING_SHIPMENT,
      STATUS.PARTIALLY_RECEIVED,
      STATUS.SHIPPED,
      STATUS.DISPUTE,
      STATUS.DELIVERED,
      STATUS.RECEIVED,
    ],
  },
  COMPLETED: {
    key: 'COMPLETED',
    ajaxKeys: [STATUS.COMPLETED],
  },
  REJECTED: {
    key: 'REJECTED',
    ajaxKeys: [STATUS.REJECTED, STATUS.TRASH, STATUS.UNWANTED],
  },
};

const OFFER_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  SENT: 'SENT',
  PAID: 'PAID',
};

const CATEGORY = {
  UNWANTED: 'UNWANTED',
  ACCEPTABLE: 'ACCEPTABLE',
  WANTED: 'WANTED',
  DEFAULT: 'NOT_DECIDED'
};

const DISPLAY_RECIEVED_STATUS = [
  STATUS.DELIVERED,
  STATUS.PARTIALLY_RECEIVED,
  STATUS.RECEIVED,
  STATUS.DISPUTE,
  STATUS.COMPLETED

];

export { CONDITIONS, STATUS, OFFER_STATUS, QUOTE_STEPS, CATEGORY, DISPLAY_RECIEVED_STATUS };
