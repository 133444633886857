import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const BrandChip = ({ brand, original, ...props }) => (
  <Chip
    label={`${original ? 'Original' : 'Compatible'} ${brand}`}
    size="small"
    {...props}
    sx={{
      fontSize: '10px',
      fontWeight: 300,
      lineHeight: '13px',
      color: 'black',
      width: 'min(100%, 163px)',
      height: '18.7px',
    }}
  />
);

export default BrandChip;
BrandChip.propTypes = {
  original: PropTypes.bool.isRequired,
  brand: PropTypes.string.isRequired
};
