import PropTypes from 'prop-types';
import { CONDITIONS } from '../../../constants/quoteConstants';
import { Button, Tooltip, styled, tooltipClasses, Box } from '@mui/material';
import ConditionInfoCard from './ConditionInfoCard';
import { useCallback, useState } from 'react';
import useIsMobileView from '../../../hooks/useIsMobileView';

const calculateTooltipWidthAndArrowPosition = (itemIndex, isMultiRow) => {
  const conditionPickerWrapper = document.getElementById(
    'condition-picker-wrapper'
  );
  if (!conditionPickerWrapper) return [0, 0];
  const getValue = (str) => (str ? +str.split('px')[0] : 0);
  const width = conditionPickerWrapper.offsetWidth;
  const buttonGap = getValue(getComputedStyle(conditionPickerWrapper).gap);

  const itemPosition = isMultiRow ? ((itemIndex + 1) % 2) + 1 : itemIndex;

  const rowGaps = isMultiRow ? 1 : 3;
  const rowButtons = isMultiRow ? 2 : 4;
  const halfButtonWidth = (width - buttonGap * rowGaps) / (rowButtons * 2);
  const arrowPosition = halfButtonWidth * (itemPosition * 2 - 1) + (itemPosition - 1) * buttonGap;

  const arrowWitdth = 16.5;
  const toolTipLeftpadding = 16;
  const tooltipSidesPadding = 32;
  const rootWidth = width + tooltipSidesPadding;
  return [rootWidth, arrowPosition + toolTipLeftpadding - arrowWitdth / 2];
};

const LightTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'arrowPosition' && prop !== 'tooltipWidth',
  }
)(({ theme, arrowPosition, tooltipWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    padding: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 5px 25px 0px #00000040',
    fontSize: 11,
    maxWidth: 'none',
    borderRadius: '8px',
    width: `${tooltipWidth}px`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    transform: `translate(${arrowPosition}px, 0px) !important`,
  },
  [`&.MuiTooltip-popper[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
    marginBottom: '-11.7px',
    height: '11.7px',
    width: '16.5px',
  },
  [`&.MuiTooltip-popper[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
    marginTop: '-11.7px',
    height: '11.7px',
    width: '16.5px',
  }
}));

const Condition = ({ value, selected, onClick, onMouseEnter }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    sx={{
      border: '1px solid #BEC5D0',
      backgroundColor: selected && '#D9D9D9 !important',
      height: '50px',
      width: {
        xs: 'calc(50% - 4px)',
        sm: 'auto'
      },
      flexGrow: {
        sm: 1
      }
    }}
    onTouchStart={onMouseEnter}
    onMouseEnter={onMouseEnter}
  >
    {value}
  </Button>
);

const ConditionPicker = ({ condition, setCondition }) => {
  const [tooltipData, setToolTipData] = useState(null);
  const [arrowPosition, setArrowPosition] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  const isMobile = useIsMobileView();
  const isConditionMultiRow = useIsMobileView('sm');

  const handleShowTooltip = useCallback((newTooltipData, buttonIndex) => {
    setToolTipData(newTooltipData);
    const [newTooltipWidth, newArrowPosition] = calculateTooltipWidthAndArrowPosition(buttonIndex + 1, isConditionMultiRow);
    setArrowPosition(newArrowPosition);
    setTooltipWidth(newTooltipWidth);
  }, [isConditionMultiRow]);
  const handleConditionClick = (newTooltipData, buttonIndex) => {
    setCondition(newTooltipData);
    if (!isMobile) return;
    setToolTipData(newTooltipData);
    const [newTooltipWidth, newArrowPosition] = calculateTooltipWidthAndArrowPosition(buttonIndex + 1);
    setArrowPosition(newArrowPosition);
    setTooltipWidth(newTooltipWidth);
  };

  return (
    <Box display="flex">
      <LightTooltip
        arrowPosition={arrowPosition}
        tooltipWidth={tooltipWidth}
        arrow
        id="condition-picker-tooltip"
        leaveTouchDelay={0}
        title={(
          <ConditionInfoCard
            value={tooltipData}
            open={!!tooltipData}
          />
        )}
      >
        <Box
          display="flex"
          width="100%"
          flexWrap={{
            xs: 'wrap',
            sm: 'nowrap'
          }}
          gap={1}
          id="condition-picker-wrapper"
        >
          {CONDITIONS.map((value, index) => (
            <Condition
              onMouseEnter={() => handleShowTooltip(value, index)}
              key={value}
              value={value}
              selected={value === condition}
              onClick={() => handleConditionClick(value, index)}
            />
          ))}
        </Box>
      </LightTooltip>
    </Box>
  );
};

export default ConditionPicker;
ConditionPicker.propTypes = {
  condition: PropTypes.string,
  setCondition: PropTypes.func,
};
Condition.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
};
