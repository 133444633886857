import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress } from '@mui/material';
import BrandChip from './BrandChip';
import ImageIcon from '@mui/icons-material/Image';
import { composeProductLabel, formatSku } from '../../../../../utils/quoteUtils';

const ItemLabelBrandCell = ({ item, itemProduct, isLoading, original }) => (!isLoading ? (
  <Box
    display="flex"
    gap={0.5}
  >
    <ProductImage plytixProduct={itemProduct} />
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      flexGrow={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography
          fontSize="15px"
          fontWeight={700}
          lineHeight="19.5px"
        >
          {formatSku(itemProduct.sku)}
        </Typography>
        <BrandChip
          brand={item?.brand ?? itemProduct?.attributes?.brand ?? 'loading...'}
          original={original}
        />
      </Box>
      <Typography
        fontSize="13px"
        fontWeight={400}
      >
        {composeProductLabel(itemProduct.label, original)}
      </Typography>
    </Box>
  </Box>
) : (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Box>
));

const ProductImage = ({ plytixProduct }) => {
  const imageSrc = plytixProduct?.attributes?.image?.thumbnail;
  if (!imageSrc) {
    return (
      <Box
        border="1px solid #C6C6C6"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="64px"
      >
        <ImageIcon />
      </Box>
    );
  }
  return (
    <Box
      border="1px solid #C6C6C6"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img
        style={{
          maxWidth: '64px',
          maxHeight: '68px'
        }}
        src={imageSrc}
        alt={`Product ${plytixProduct?.label}`}
      />
    </Box>
  );
};

export default ItemLabelBrandCell;
ProductImage.propTypes = {
  plytixProduct: PropTypes.object,
};
ItemLabelBrandCell.propTypes = {
  isLoading: PropTypes.bool,
  item: PropTypes.object,
  itemProduct: PropTypes.object,
  original: PropTypes.bool.isRequired
};
