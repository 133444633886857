import PropTypes from 'prop-types';
import { Select, MenuItem, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { generateNumberArray } from '../../utils/numberUtils';

const FIXED_OPTIONS = generateNumberArray(9, (index) => index + 1);

const QuantitySelector = ({ value, onChange, ...props }) => {
  const [displayInput, setDisplayInput] = useState(value && value >= 10);
  const [focusInput, setFocusInput] = useState(false);
  const textInputRef = useRef(null);

  const handleQuantityChange = (newValue) => {
    if (newValue === '') {
      onChange();
      return;
    }
    const parsedValue = Number.parseInt(newValue, 10);
    if (!Number.isNaN(parsedValue) && parsedValue) onChange(parsedValue);
  };

  const handleDisplayInput = () => {
    setDisplayInput(true);
    setFocusInput(true);
  };

  useEffect(() => {
    if (focusInput && textInputRef.current) {
      textInputRef.current.focus();
      setFocusInput(false);
    }
  }, [focusInput]);

  if (!displayInput) {
    return (
      <Select
        fullWidth
        id="quantityInput"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        {...props}
      >
        {FIXED_OPTIONS.map((option) => (
          <MenuItem
            value={option}
            key={option}
          >
            {option}
          </MenuItem>
        ))}
        <MenuItem onClick={handleDisplayInput}>10+</MenuItem>
      </Select>
    );
  }

  return (
    <TextField
      onChange={(event) => handleQuantityChange(event.target.value)}
      type="number"
      value={value ?? ''}
      variant="outlined"
      inputRef={textInputRef}
      {...props}
    />
  );
};

export default QuantitySelector;
QuantitySelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
