const DATE_FORMATS = {
  DOTTED: {
    NUMERIC: 'MM.dd.yyyy',
  },
  COMMA: {
    WEEK_DAY: 'EEEE, MMM dd, yyyy',
    DAY: 'MMM dd, yyyy',
  },
  SLASHED: {
    TIME: 'MM/dd/yyyy HH:mm',
    DEFAULT: 'M/dd/yyyy',
    NUMERIC: 'MM/dd/yyyy',
  },
};

export default DATE_FORMATS;

export const LOCALIZATION_DATE_FORMATS = {
  fullDate: DATE_FORMATS.SLASHED.NUMERIC,
};
