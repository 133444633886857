import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { Fade } from 'react-slideshow-image';
import useIsMobileView from '../../../hooks/useIsMobileView';

const CARD_DATA = {
  A: {
    TITLE: 'A - Perfect Box',
    IMAGES: [
      { url: '/vendor/images/condition/a.webp' }
    ],
    LIST: [
      {
        POSITIVE: true,
        TEXT: 'New & Sealed',
      },
      {
        POSITIVE: true,
        TEXT: 'Retail Condition',
      },
      {
        POSITIVE: true,
        TEXT: 'No Writing',
      },
      {
        POSITIVE: true,
        TEXT: 'Hologram Intact',
      },
      {
        POSITIVE: true,
        TEXT: 'Clean Box',
      },
    ],
  },
  B: {
    TITLE: 'B - Imperfect Box',
    IMAGES: [
      { url: '/vendor/images/condition/b.webp' }
    ],
    LIST: [
      {
        POSITIVE: true,
        TEXT: 'Labels on Box',
      },
      {
        POSITIVE: true,
        TEXT: 'Minor Dents',
      },
      {
        POSITIVE: true,
        TEXT: 'Hologram Scratched',
      },
      {
        POSITIVE: true,
        TEXT: 'Dusty Box',
      },
      {
        POSITIVE: true,
        TEXT: 'Shelf Wear',
      },
    ],
  },
  C: {
    TITLE: 'C - Damaged Box',
    IMAGES: [
      { url: '/vendor/images/condition/c.webp' }
    ],
    LIST: [
      {
        POSITIVE: true,
        TEXT: 'Rips on Box',
      },
      {
        POSITIVE: true,
        TEXT: 'Hologram Damaged',
      },
      {
        POSITIVE: true,
        TEXT: 'Writing on Box',
      },
      {
        POSITIVE: true,
        TEXT: 'Dents',
      },
    ],
  },
  D: {
    TITLE: 'D - We Do Not Pay For These Items',
    IMAGES: [
      { url: '/vendor/images/condition/d.webp' }
    ],
    LIST: [
      {
        POSITIVE: false,
        TEXT: 'Compatible Brand',
      },
      {
        POSITIVE: false,
        TEXT: 'Open or Missing Box',
      },
      {
        POSITIVE: false,
        TEXT: 'Water Damaged Box',
      },
      {
        POSITIVE: false,
        TEXT: 'Broken/Missing Hologram',
        MOBILE_TEXT: 'Broken Hologram'
      },
      {
        POSITIVE: false,
        TEXT: 'Used/Empty',
      },
    ],
  },
};

const ConditionInfoCard = ({ value }) => {
  const CONDITION_DATA = CARD_DATA[value];
  const isMobile = useIsMobileView();
  if (!CONDITION_DATA) return null;

  return (
    <Box>
      <Typography fontWeight="bold">{CONDITION_DATA.TITLE}</Typography>
      <Grid container>
        <Grid
          item
          xs={5}
          py={1}
        >
          <Box
            border="1px solid #C6C6C6"
            maxWidth="150px"
            className="slide-container"
          >
            <Fade arrows={false}>
              {CONDITION_DATA.IMAGES.map((fadeImage, index) => (
                <Box
                  height="100%"
                  className="each-fade"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <Box
                    height="100%"
                    className="image-container"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      width="100%"
                      src={fadeImage.url}
                      alt="Condition"
                    />
                  </Box>
                </Box>
              ))}
            </Fade>
          </Box>
        </Grid>
        <Grid
          item
          xs={7}
          pl={1}
        >
          <List sx={{ fontSize: '15px' }}>
            {CONDITION_DATA.LIST.map((element) => (
              <ListItem
                disablePadding
                key={element.TEXT}
                my={0}
              >
                <ListItemIcon sx={{ minWidth: 0, pr: 0.5, fontSize: '1.2em' }}>
                  {element.POSITIVE ? (
                    <CheckIcon
                      fontSize="ingetir"
                      color="highlight"
                    />
                  ) : (
                    <CloseIcon
                      fontSize="inherit"
                      sx={{ color: '#F55252' }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      whiteSpace: 'nowrap',
                      wordBreak: 'keep-all',
                      lineHeight: '12px !important',
                    },
                  }}
                >
                  {(isMobile && element.MOBILE_TEXT) ? element.MOBILE_TEXT : element.TEXT}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConditionInfoCard;
ConditionInfoCard.propTypes = {
  value: PropTypes.string,
};
